export const Footer = () => {
  return (
    <div className="app-footer">
      <a href="/Felix Handschuh.vcf" className="footer-text">
        <img
          className="footer-thumbnail"
          src={"/icon_contact.webp"}
          alt="Imprint"
        />
      </a>

      <a href="/imprint" className="footer-text">
        <img
          className="footer-thumbnail"
          src={"/icon_imprint.webp"}
          alt="Switch Language"
        />
      </a>
    </div>
  );
};

<a href="/imprint" className="footer-text">
  <img
    className="footer-thumbnail"
    src={"/icon_language.webp"}
    alt="Switch Language"
  />
</a>;
