import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import main from "./main";
import privacy from "./projects";
import terms from "./about";
import imprint from "./imprint";
import { BrowserRouter, Routes, Route } from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route index element={main()}></Route>
        <Route path="/projects" element={privacy()}></Route>
        <Route path="/imprint" element={imprint()}></Route>
        <Route path="/about" element={terms()}></Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
