import "./main.css";
import { Header } from "./header";
import { Footer } from "./footer";

function main() {
  return (
    <div className="app">
      <Header hideContact={false} />

      <div className="hero-container">
        <div className="hero-text">
          <div className="h1">
            Product Designer working at the intersection of tech, design &
            health
          </div>

          <div className="h2">
            Hi, I{"'"}m Felix, a designer based in Berlin. I like to work on
            things that make life easier.
          </div>

          <div className="social-bar">
            <a href="https://www.figma.com/@Handschuh" className="social-link">
              <img
                className="social-thumbnail"
                src={"/icon_figma_colour_3x.webp"}
                alt="Link to my Figma Community Page"
              />
            </a>

            <a
              href="https://github.com/felix-handschuh"
              className="social-link"
            >
              <img
                className="social-thumbnail"
                src={"/icon_github_colour_3x.webp"}
                alt="Link to my GitHub account"
              />
            </a>

            <a
              href="https://uiuxdev.social/@FelixHandschuh"
              className="social-link"
            >
              <img
                className="social-thumbnail"
                src={"/icon_mastodon_colour_3x.webp"}
                alt="Link to my Mastodon account"
              />
            </a>

            <a
              href="https://www.linkedin.com/in/felix-🤝-handschuh-05205819/"
              className="social-link"
            >
              <img
                className="social-thumbnail"
                src={"/icon_linkedin_colour_3x.webp"}
                alt="Link to my LinkedIn account"
              />
            </a>

            <a
              href="https://dribbble.com/FelixHandschuh/"
              className="social-link"
            >
              <img
                className="social-thumbnail"
                src={"/icon_dribbble_colour_3x.webp"}
                alt="Link to my Dribbble account"
              />
            </a>
          </div>
        </div>
      </div>

      <div className="spacer" />

      <Footer />
    </div>
  );
}

export default main;
