import "./main.css";
import { Header } from "./header";

function imprint() {
  return (
    <div className="app">
      <Header hideContact={false} />

      <div className="legal">
        <div className="h3">Legal information</div>

        <div className="body-grey">
          This website does not collect, store or process any personally
          identifiable information and does not use any cookies.
        </div>
        <br></br>

        <div className="body-grey">This website is hosted on Netlify.</div>
      </div>
    </div>
  );
}

export default imprint;
