export const Header = () => {
  return (
    <div className="app-header">
      <a href="/" className="app-header-logo">
        <img
          className="header-icon"
          src={"/Logo_2x.webp"}
          alt="This is an emoji with me on it."
        />
      </a>
      <span className="spacer" />
      <div className="navigation-bar">
        <a href="/" className="tab">
          Home
        </a>
        <a href="/about" className="tab">
          About me
        </a>
        <a href="/projects" className="tab">
          Projects
        </a>
      </div>
    </div>
  );
};
