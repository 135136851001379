import "./main.css";
import { Header } from "./header";
import { Footer } from "./footer";

function projects() {
  return (
    <div className="app">
      <Header hideContact={false} />

      <div className="hero-container">
        <div className="hero-text">
          <div className="h3">Projects</div>

          <div class="card-container">
            <div class="card">
              <div>
                <img
                  className="selfie"
                  src={"projects_rezepte_lol.webp"}
                  alt="Thumbnail of rezepte.lol"
                />
              </div>

              <div className="chip-app">App</div>
              <div className="card-title">rezepte.lol</div>

              <div className="body">
                Hassle-free alternative for e-prescriptions with end-to-end
                encryption.
              </div>
              <a href="https://rezepte.lol/" className="primary-button">
                Learn more ↗️
              </a>
            </div>

            <div class="card">
              <div>
                <img
                  className="selfie"
                  src={"/projects_webp.webp"}
                  alt="Thumbnail of WebP"
                />
              </div>
              <div className="chip-plugin">Plugin</div>
              <div className="card-title">WebP Generator</div>

              <div className="body">
                Export image files as webp to keep your app/website lean and
                lightweight.
              </div>
              <a
                href="https://www.figma.com/community/plugin/1181873200384736932/WebP-Generator"
                className="primary-button"
              >
                Learn more ↗️
              </a>
            </div>

            <div class="card">
              <div>
                <img
                  className="selfie"
                  src={"/projects_scrumalot.webp"}
                  alt="Thumbnail of Scrum-A-Lot"
                />
              </div>
              <div className="chip-plugin">Plugin</div>
              <div className="card-title">Scrum-A-Lot</div>

              <div className="body">
                Simple Planning Poker to help teams planning their upcoming
                sprints. Recommended by Figma 😎.
              </div>
              <a
                href="https://www.figma.com/community/widget/1072985192032373399/Scrum-A-Lot"
                className="primary-button"
              >
                Learn more ↗️
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="spacer" />

      <Footer />
    </div>
  );
}

export default projects;
