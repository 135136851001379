import "./main.css";
import { Header } from "./header";
import { Footer } from "./footer";

function about() {
  return (
    <div className="app">
      <Header />

      <div className="hero-container">
        <div className="hero-text">
          <div className="h3">About me</div>

          <div>
            <img
              className="selfie"
              src={"/felix.webp"}
              alt="Picture of me standing in front of a wall.
              I have full, superthick blond hair, blue eyes and a beard.
              Na, just kidding: I am bald as an egg."
            />
          </div>

          <div className="body">
            <p>
              <b>Tl;dr:</b> I{"'"}m Felix, father of two daughters with 12 years
              of experience in the field of mobile apps and websites and I{"'"}m
              living in Berlin. I like to make digital products that make life
              better for others.
            </p>
            <br></br>
            <p>
              While in university I worked for a company that equipped museums
              with audio guides and audiostations. It was wild. It was the time
              of glossy aqua buttons and we provided museums across Europe with
              apps and digital installations.
              <br></br>After graduation I became a project manager at{" "}
              <a href="https://www.neofonie-mobile.de/">
                <b>neofonie mobile</b>
              </a>{" "}
              (a Berlin app agency focused on newspaper apps). There I gained
              first experience in managing teams. Thanks to my experience in
              designing newspaper apps, Funke Mediengruppe, a large German
              publisher, hired me in 2015. There I was responsible for launching
              a new website called{" "}
              <a href="https://www.behance.net/gallery/93946019/News-Der-Westen">
                <b>DerWesten</b>
              </a>
              . Today DerWesten is the most successful website of Funke in terms
              of traffic and reach.
              <br></br>
              <br></br>
              Shortly after the birth of our first daughter, I joined{" "}
              <a href="https://www.aposync.de">
                <b>ApoSync</b>
              </a>{" "}
              as co-founder, product designer, marketing person and user
              researcher and got to know the world of pharmacies. Our app aimed
              at pharmacy teams, providing medication recalls and red hand
              letters (super urgent medication recalls) as a digital service.
              Over 2000 pharmacies relied on us for this and rewarded us with
              the near-best score in the stores (4.8 ⭐️). After 3 years we made
              the decision to sell the company to ARZ Haan.
              <br></br>
              <br></br>
              The interest in health tech remained and I therefore decided to
              hire at the source, the state, somehow. At gematik I am mainly
              working on the eRezept app (for{" "}
              <a href="https://play.google.com/store/apps/details?id=de.gematik.ti.erp.app&hl=de&gl=US">
                <b>Android</b>
              </a>
              ,{" "}
              <a href="https://apps.apple.com/de/app/das-e-rezept/id1511792179">
                <b>iOS</b>
              </a>
              , and even a{" "}
              <a href="https://apps.microsoft.com/store/detail/erezept/9NMLLBN5XJ02?hl=de-de&gl=de">
                <b>Windows client</b>
              </a>{" "}
              ) as a product designer, but I am also involved in other projects
              in parallel: Authentication clients for the registration at the
              German Organ Donor Registry, the interface for a directory service
              and various smaller projects.
              <br></br>
              <br></br>I only recently have also discovered the joy of small
              side projects. Helping designers to build websites for example or
              to make it easier for people to get started with eRezept.
            </p>
          </div>

          <div className="h4">Work</div>

          <table class="customTable">
            <tbody>
              <tr>
                <td>
                  <b>gematik</b>
                </td>
                <td>Product Designer</td>
                <td>2020 – </td>
              </tr>
              <tr>
                <td>
                  <b>ApoSync</b>
                </td>
                <td>Co-Founder</td>
                <td>2017 – 2020</td>
              </tr>
              <tr>
                <td>
                  <b>Funke Digital</b>
                </td>
                <td>Product Designer</td>
                <td>2015 – 2017</td>
              </tr>
              <tr>
                <td>
                  <b>neofonie mobile</b>
                </td>
                <td>Project Manager</td>
                <td>2013 – 2015</td>
              </tr>
              <tr>
                <td>
                  <b>tonwelt</b>
                </td>
                <td>Project Manager</td>
                <td>2009 – 2013</td>
              </tr>
            </tbody>
          </table>

          <div className="secondary-button">
            <a href="/CV_Felix-Handschuh.pdf">Download CV</a>
          </div>
        </div>
      </div>
      <div className="spacer" />
      <Footer />
    </div>
  );
}

export default about;
